import styles from '../css/clickableOptions.module.scss';
import { useState, useEffect } from 'react';
import { stringToBoolean } from '../../functions/commonFunctions.js';

export const ClickableOptions = (props) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const boolValue = stringToBoolean(urlParams.get('demo'));

    const [demoMode, setDemoMode] = useState(boolValue);

    useEffect(() => {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        params.set('demo', demoMode);
        url.search = params.toString();
        window.history.pushState({}, '', url.toString());
    }, [demoMode])

    const toggleDemoMode = () => {
        setDemoMode(prev => !prev)
        props.hideDemoModal();
    }

    return (
        <div className={styles.container}>
            <form className="ml-2">
                <label>Demo mode</label>
                <input 
                    type="checkbox" 
                    className="ml-1 h-5 w-5 hover:cursor-pointer" 
                    onChange={toggleDemoMode} 
                    checked={demoMode}
                />
            </form>
        </div>
    )
}