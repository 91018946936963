import './App.css';
import useToken from './useToken.js';
import { LogIn } from './components/LogIn';
import { Dashboard } from './components/Dashboard';

const App = () => {
  const { token, setToken } = useToken();

  //to enable log in, add an exclamation mark before token
  if (token) {
    return <LogIn setToken={setToken} />
  }

  return (
    <Dashboard/>
  );
}

export default App;