import Select from 'react-dropdown-select';

export const FixtureSelector = (props) =>{ 
    return (
        <section className="mt-2">
            <label htmlFor="competition">Match</label>
            <br />
            <Select options={props.todaysFixtures} onChange={(values) => props.handleChange(values)} style={{backgroundColor:"#E1E1E1"}} color="#E1E1E1" />
        </section> 
    )
}