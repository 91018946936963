import Select from 'react-dropdown-select';

export const CompetitionSelector = (props) =>{
    return (
        <section>
            <label>Competition</label>
            <br />
            <Select options={props.leagues} onChange={(value) => props.onChange(value)} style={{backgroundColor:"#E1E1E1"}} color="#E1E1E1"/>
        </section>
    )
}