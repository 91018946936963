import styles from './css/tilesContainer.module.scss';
import { useEffect, useState } from 'react';
import { TileContainer } from './TileContainer';
import { AddTile } from './AddTile';
import { MenuIcon } from './MenuIcon';
import { generateTilesCSS } from '../functions/generateTilesCSS';
import { getFixtureById, getFixturesByIds } from '../services/fixtureService.js';

export const TilesContainer = (props) => {
    const [keys, setKeys] = useState([0]);
    const [fixtureids, setFixtureIds] = useState([]);
    const [bsClass, setBsClass] = useState('col-lg-6');

    const [ultimateFixtures, setUltimateFixtures] = useState([{key:0,fixtureId:-1},{key:1,fixtureId:-1}]);
    const [fetchedSessionStorage, setFetchedSessionStorage] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        //starts timer to update the timer state, which then triggers the useEffect below this one to refresh the scores

        //below is commented out because it's causing build issues and i'm not sure of it's purpose
        //const now = new Date();
        //const sessionNextRefresh = new Date(sessionStorage.getItem('nextRefresh'));

        //let nextRefresh = (sessionNextRefresh - now);

        /*
        const catchUpRun = setTimeout(() => {
            setTimer(prevTime => prevTime + 1);
        }, nextRefresh);
        */

        const interval = setInterval(() => {
            setTimer(prevTime => prevTime + 1);
        }, 60000);
        return () => clearInterval(interval);
    },[])

    useEffect(() => {
        //runs every minute, refreshing scores
        const refreshData = async () => {
            const now = new Date()
            console.log('Refreshed scores at ' + now);
            const nextRefresh = new Date(now.getTime() + 60000);
            sessionStorage.setItem('nextRefresh', nextRefresh);

            const refreshedFixtureData = await getFixturesByIds(fixtureids);
    
            let tempUltimateFixtures = ultimateFixtures;
            for (let i=0; i < refreshedFixtureData.response.length; i++){
                tempUltimateFixtures[i].fixtureData = refreshedFixtureData.response[i];
            }
    
            setUltimateFixtures(tempUltimateFixtures);
        }

        if (fixtureids.filter(x => x !== -1).length > 0) {
            refreshData()
        }

        //the next comment disables an eslint rule that throws the error: 'useeffect has a missing dependency: either include it or remove the dependency array'
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer])

    useEffect(()=> {
        //fetches tilesData array that is stored in sessionsStorage
        //only runs on first render of component 
        //component is rerendered when page is refreshed, so it runs when page is refreshed
        console.log('fetching session storage')

        const sessionTilesData = JSON.parse(sessionStorage.getItem('tilesData'));
        setFetchedSessionStorage(true);

        if (sessionTilesData !== null) {
            //get fixtures data
            const asyncShell = async () => {
                for (let tile of sessionTilesData) {
                    if (tile.fixtureId > -1) {
                        let data = null;
                        if (tile.data == null) {
                            data =  await getFixtureById(tile.fixtureId);
                            tile.fixtureData = data?.response[0]
                        } else {
                            data = tile.data;
                            tile.fixtureData = data;
                        }
                    }
                }

                setUltimateFixtures(sessionTilesData);
                const generatedCss = generateTilesCSS(sessionTilesData.length);
                setBsClass(generatedCss);
            }

            asyncShell();
        }
    },[])

    useEffect(() => { 
        //sets states keys and fixtureids and loads them in to session storage
        let tempKeys = [];
        let tempFixtureIds = [];
        let tempFixtureData = [];

        for (const fixture of ultimateFixtures) {
            tempKeys.push(fixture.key);

            if (fixture.fixtureId === undefined) {
                tempFixtureIds.push(-1);
            }
            else {
                tempFixtureIds.push(fixture.fixtureId);
                tempFixtureData.push({
                    teams:{
                        home: {
                            name: fixture.fixtureData?.teams?.home?.name,
                            logo: fixture.fixtureData?.teams?.home?.logo
                        },
                        away: {
                            name: fixture.fixtureData?.teams?.away?.name,
                            logo: fixture.fixtureData?.teams?.away?.logo
                        }
                    },
                    goals:{
                        home: fixture.fixtureData?.goals?.home, 
                        away: fixture.fixtureData?.goals?.away
                    }});
            }
        }
        setKeys(tempKeys);
        setFixtureIds(tempFixtureIds);

        if (fetchedSessionStorage === true) {
            let tempSessionStorage = [];
            for (let i=0; i < tempKeys.length; i++) {
                tempSessionStorage.push({key: tempKeys[i], fixtureId: tempFixtureIds[i], data: tempFixtureData[i]})
            }
            sessionStorage.setItem('tilesData',JSON.stringify(tempSessionStorage));
        }
    }, [ultimateFixtures,fetchedSessionStorage])

    const addTile = () => {
        let max = Math.max(...keys);
        setUltimateFixtures(oldFixtures => [...oldFixtures, {key: max+1}]);

        const generatedCss = generateTilesCSS(keys.length+1);
        setBsClass(generatedCss);
    }

    const removeTile = (tilekey) => {
        let tempUltimateFixtures = ultimateFixtures.filter(fixture => fixture.key !== tilekey);
        setUltimateFixtures(tempUltimateFixtures);

        const generatedCss = generateTilesCSS(tempUltimateFixtures.length);
        setBsClass(generatedCss);
    }

    const updateFixtureIds = async (fixtureid, add, tileKey) => {
        let tempUltimateFixtures = [...ultimateFixtures];
        const data =  await getFixtureById(fixtureid);

        for (let fixture of tempUltimateFixtures) {
            if (fixture.key === tileKey) {
                fixture.fixtureId = fixtureid;
                fixture.fixtureData = data.response[0];
            }
        }

        setUltimateFixtures(tempUltimateFixtures);
    }

    return (
        <div className={`container-fluid ${styles.container}`}>
            {props.menuOpen !== true ? <MenuIcon onClick={props.onMenuIconClick} /> : null}
            <div className="row h-100">
              {ultimateFixtures.map(i => <TileContainer 
                key={i.key} 
                keyvalue={i.key}
                bsWidthClass={bsClass} 
                backgroundColor={props.tileColour}
                counterColour={props.backgroundColour}
                removeTile={keys.length>1 ? removeTile : null} 
                updateFixtureIds={updateFixtureIds} 
                fixtureData={i.fixtureData}
                menuOpen={props.menuOpen}
                />)}
            </div>
            {keys.length < 9 ? <AddTile onClick = {addTile} menuOpen={props.menuOpen}/> : null}
        </div>
    )
}