import { createClient } from '@supabase/supabase-js'

// Create a single supabase client for interacting with your database
const supabase = createClient('https://cuhpnvfsqgjycuxdrcrz.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN1aHBudmZzcWdqeWN1eGRyY3J6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzAxNDkwMzEsImV4cCI6MTk4NTcyNTAzMX0.XEcyn_My-3D4dJs2cvFl9tSESR1cWpDZDhCSV4q1TJI')


export const signUpUser = async (email, password) => {
    const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
    })

    console.log(data,error);
}

export const logInUSer = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
    })

    return {data, error}
}