import { useEffect, useState } from 'react';
import { TilesContainer } from './TilesContainer';
import { Menu } from './Menu';
import { stringToBoolean } from '../functions/commonFunctions.js';

export const Dashboard = (props) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const boolValue = stringToBoolean(urlParams.get('demo'));

    const [demoModalOpen, setDemoModalOpen] = useState(boolValue);
    const [menuOpen, setMenuOpen] = useState(JSON.parse(sessionStorage.getItem('menuOpen')) ?? true);
    const [tileColour, setTileColour] = useState(sessionStorage.getItem('tileColour') ?? '#3c6e71');
    const [backgroundColour, setBackgroundColour] = useState(sessionStorage.getItem('backgroundColour') ?? '#95afa4');

    const toggleMenu = () => {
      menuOpen === true ? setMenuOpen(false) : setMenuOpen(true);
    }

    useEffect(() => {
      sessionStorage.setItem('menuOpen',JSON.parse(menuOpen));
    },[menuOpen])

    useEffect(() => {
      sessionStorage.setItem('backgroundColour',backgroundColour);
    },[backgroundColour])

    useEffect(() => {
      sessionStorage.setItem('tileColour',tileColour);
    },[tileColour])

    return (
      <>
      <div style={{ backgroundColor: backgroundColour, height: '100vh'}}>
          {menuOpen === true ? <Menu 
                                  onCloseMenuClick={toggleMenu} 
                                  onColourChange={setTileColour} 
                                  onBackgroundChange={setBackgroundColour} 
                                  tileColour={tileColour} 
                                  backgroundColour={backgroundColour}
                                  hideDemoModal={() => setDemoModalOpen(false)}
                                /> : null}
          <TilesContainer
            onMenuIconClick={toggleMenu}
            menuOpen={menuOpen}
            tileColour={tileColour}
            backgroundColour={backgroundColour}
          />
      </div>
      {
        demoModalOpen ?
        <div className="px-4 pb-4 pt-1 rounded-md text-center bg-red-500 absolute bottom-1 left-1/2 -translate-x-1/2 ">
        <button 
          className="px-2 text-xl relative left-1/2"
          onClick={() => setDemoModalOpen(false)}
        >
          &#x2716;
        </button>
        <p>Demo mode is enabled, meaning the fixtures shown are from Boxing day of 2022.</p>
        <p>To view today's fixtures, open the side menu and disable demo mode.  </p>
        </div>
        : null
      }
      </>
    )
}