//This function should return an array of objects container the bootstrap class and height
export const generateTilesCSS = (i) => {
        let bootstrapClass;

        switch(i) {
            case 1:
                bootstrapClass = 'col-12';
                break;
            case 2:
                bootstrapClass = 'col-lg-6';
                break;
            case 3:
                bootstrapClass = 'col-lg-4';
                break;
            case 4:
                bootstrapClass = 'col-md-6';
                break;
            case 5:
                bootstrapClass = 'col-lg-4 col-md-6';
                break;
            case 6:
                bootstrapClass = 'col-lg-4 col-md-6';
                break;
            case 7:
                bootstrapClass = 'col-lg-4 col-md-6';
                break;
            case 8:
                bootstrapClass = 'col-lg-4 col-md-6';
                break;
            case 9:
                bootstrapClass = 'col-md-4';
                break;
            default:
                bootstrapClass='';
        }

        return bootstrapClass;
}