import styles from '../css/closeMenu.module.scss';

export const CloseMenu = (props) => {
    return (
        <div style={{width:'100%', overflow:'auto'}}>
            <div className={styles.container}>
                <img src="left-arrow.png" className={styles.img} onClick={() => props.onClick(false)} alt="close menu"/>
            </div>
        </div>
        
    )
}