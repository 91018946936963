import styles from './css/menuIcon.module.scss';
import commonstyles from './css/commonButtons.module.scss';
import { useState } from 'react';

export const MenuIcon = (props) => {
    const [animationClass, setAnimationClass] = useState();

    const handleMouseOver = () => {
        setAnimationClass(styles.hold)
    };
    
    const handleMouseOut = () => {
        setAnimationClass(styles.flyout)
    };  

    return (
        <div className={`${styles.surcontainer} ${commonstyles.common_surcontainer}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div className = {`${styles.container} ${commonstyles.common_container} ${animationClass}`} onClick={props.onClick}>
                    <div className = {commonstyles.common_circle}>
                        <img src="menu.png" alt="add tile" className={commonstyles.common_img}/>
                    </div>
                </div>
        </div>
    )
}