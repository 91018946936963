import "../css/defaultColours.css";
import styles from '../css/defaultColours.module.scss';
import { useState, useEffect } from 'react';
import { HexColorPicker } from "react-colorful";



export const DefaultColours = (props) => {
    const [colour, setColour] = useState(props.tileColour);   
    const [backgroundColour, setBackgroundColor] = useState(props.backgroundColour); 

    useEffect(() => {
        props.onColourChange(colour);
    },[colour, props])

    useEffect(() => {
        props.onBackgroundChange(backgroundColour);
    },[backgroundColour, props])

    return (
        <div className={styles.container}>
            <h4 className={styles.h3}>Colours</h4>
            <div className="hcpsurcontainer">
                <div className="hcpcontainer">
                    <h6>Tile</h6>
                    <HexColorPicker color={colour} onChange={setColour} />
                </div>    
                <div className="hcpcontainer">
                    <h6>Background</h6>
                    <HexColorPicker color={backgroundColour} onChange={setBackgroundColor} />
                </div> 
            </div>
        </div>
    )
}