import { Score } from './Score.js';
import { useEffect, useState } from 'react';

export const FixtureDetails = (props) => {
    const [score, setScore] = useState([2,0]);
    const [teams, setTeams] = useState(['Watford', 'Fulham']);
    const [logos, setLogos] = useState([]);

    useEffect(() => {
        const propsTostate = () => {
            setScore([props.fixtureData?.goals.home, props.fixtureData?.goals.away]);
            setTeams([props.fixtureData?.teams.home.name, props.fixtureData?.teams.away.name]);
            setLogos([props.fixtureData?.teams.home.logo, props.fixtureData?.teams.away.logo])
        }
        propsTostate();
    },[props.fixtureData])

    return (
        <div className="w-100 h-1/2 flex justify-center items-center">
            <Score score={score} teams={teams} logos={logos} counterColour={props.counterColour}/>
        </div>
    )
}