import './css/addfixture.css';
import { FixtureSelector } from './addFixtureForm/FixtureSelector.js';
import { useState, useEffect } from 'react';
import { getTodaysFixturesService } from '../services/fixtureService.js';
import { CompetitionSelector } from './addFixtureForm/CompetitionSelector';

export const AddFixture = (props) => {
    const [comp, setComp] = useState();
    const [fixtureId, setFixtureId] = useState();
    const [todaysFixtures, setTodaysFixtures] = useState();
    const [leagues, setLeagues] = useState([
        {value: 39, label: "Premier League"},
        {value: 40, label:"Championship"},
        {value: 41, label:"League One"},
        {value: 42, label:"League Two"},
        {value: 78, label:"Bundesliga"},
        {value: 140, label:"La Liga"},
        {value: 61, label:"Ligue 1"},
        {value: 135, label:"Seria A"}
    ]);

    useEffect(() => {
        //just doing this to use setLeagues to avoid an error
        setLeagues([
            {value: 39, label: "Premier League"},
            {value: 40, label:"Championship"},
            {value: 41, label:"League One"},
            {value: 42, label:"League Two"},
            {value: 78, label:"Bundesliga"},
            {value: 140, label:"La Liga"},
            {value: 61, label:"Ligue 1"},
            {value: 135, label:"Seria A"}
        ])
    },[])

    const getLeaguesFixtures = async (leagueid) => {
        let data = await getTodaysFixturesService(leagueid);
        await formatDataUpdateState(data);
    }

    const formatDataUpdateState = async (data) => {
        let fixturesToSet = [];
        data?.response?.forEach((fixture) => {
            let tempFixture = {
                value: fixture.fixture.id,
                label: fixture.teams.home.name + " vs " + fixture.teams.away.name
            }
            fixturesToSet.push(tempFixture);
        })

        /*
        testing array
        let fixturesToSet = [
            {
                "id": 868046,
                "home": "Aston Villa",
                "away": "Chelsea"
            },
            {
                "id": 868049,
                "home": "Leeds",
                "away": "Arsenal"
            },
            {
                "id": 868052,
                "home": "Manchester United",
                "away": "Newcastle"
            },
            {
                "id": 868053,
                "home": "Southampton",
                "away": "West Ham"
            },
        ]
        */
        setTodaysFixtures(fixturesToSet);
    }

    

    const handleCompChange = (value) => {
        if (value.length > 0) {
            setComp(value[0].value);
            getLeaguesFixtures(value[0].value);
        }
        else {
            setComp()
        }
        
    }

    const handleFixtureChange = (values) => {
        setFixtureId(values[0].value)
    }

    const onSubmit = (event) => {
        event.preventDefault();
        props.handleChange({compId: comp, fixtureId: fixtureId});
    }

    return (
        <div>
            <p className="text-2xl">Add fixture</p>
            <form onSubmit={onSubmit} className="form mt-2">
                <CompetitionSelector onChange = {handleCompChange} leagues={leagues}/>
                {comp === undefined ? null : <FixtureSelector todaysFixtures = {todaysFixtures} handleChange={handleFixtureChange}/>}
                <br />
                <input type="submit" value="Add" className="bg-slate-300 px-1 py-0.5"></input>
            </form>
        </div>
    )
}