import styles from './css/addTile.module.scss';
import commonstyles from './css/commonButtons.module.scss';
import { useState } from 'react';

export const AddTile = (props) => {
    const [animationClass, setAnimationClass] = useState();

    const handleMouseOver = () => {
        setAnimationClass(styles.hold)
    };
    
    const handleMouseOut = () => {
        setAnimationClass(styles.flyout)
    };                  

    return (
        <div className={`${styles.surcontainer} ${commonstyles.common_surcontainer}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div className = {`${styles.container} ${commonstyles.common_container} ${props.menuOpen === true ? styles.hold : animationClass}`} onClick={props.onClick}>
                    <div className = {commonstyles.common_circle}>
                        <img src="add-new.png" alt="add tile" className="w-96"/>
                    </div>
                </div>
        </div>
    )
}