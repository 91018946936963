import styles from './css/menu.module.scss';
import { CloseMenu } from './menu/CloseMenu';
import { ClickableOptions } from './menu/ClickableOptions';
import { DefaultColours } from './menu/DefaultColours';

export const Menu = (props) => {

    return (
        <div className={styles.container}>
            <CloseMenu onClick={props.onCloseMenuClick}/>
            <DefaultColours onColourChange={props.onColourChange} onBackgroundChange={props.onBackgroundChange} tileColour={props.tileColour} backgroundColour={props.backgroundColour}/>
            <ClickableOptions hideDemoModal={props.hideDemoModal}/>
        </div>
    )
}