export const getFixtureById = async (id) => {
    console.log("getting fixture information");

    const apiKey = await fetch('https://tangerine-jelly-8f2263.netlify.app/af0d8cbc-b265-4643-8590-014d4450e666')
                              .then((response) =>{ return response.text()});

    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': apiKey,
            'X-RapidAPI-Host': 'api-football-v1.p.rapidapi.com'
        }
    };

    const requestUrl = `https://api-football-v1.p.rapidapi.com/v3/fixtures?id=${id}`

    let data = await fetch(requestUrl, options)
                    .then(response => response.json())
                    //.then(response => console.log(response))
                    .catch(err => console.error(err));

    return data;
}

export const getFixturesByIds = async (ids) => {
    console.log("fetching multiple");

    const apiKey = await fetch('https://tangerine-jelly-8f2263.netlify.app/af0d8cbc-b265-4643-8590-014d4450e666')
                              .then((response) =>{ return response.text()});

    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': apiKey,
            'X-RapidAPI-Host': 'api-football-v1.p.rapidapi.com'
        }
    };

    let requestUrl = 'https://api-football-v1.p.rapidapi.com/v3/fixtures?ids=';

    for (let i=0; i<ids.length; i++) {
        if (i < ids.length-1) {
            requestUrl += `${ids[i]}-`;
        } else {
            requestUrl += `${ids[i]}`;
        }
    }

    let data = await fetch(requestUrl, options)
                    .then(response => response.json())
                    //.then(response => console.log(response))
                    .catch(err => console.error(err));

    return data;
}

export const getTodaysFixturesService = async (leagueid) => {
    console.log('getting todays fixtures')

    const apiKey = await fetch('https://tangerine-jelly-8f2263.netlify.app/af0d8cbc-b265-4643-8590-014d4450e666')
                              .then((response) =>{ return response.text()});

    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': apiKey,
            'X-RapidAPI-Host': 'api-football-v1.p.rapidapi.com'
        }
    };

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();

    let season = 2022;

    if (day<10) {
        day = `0${day}`;
    }

    if (month<10) {
        month = `0${month}`;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const demomode = urlParams.get('demo');

    let url;
    if (demomode === 'true') {
        url = `https://api-football-v1.p.rapidapi.com/v3/fixtures?date=2022-12-26&league=${leagueid}&season=2022`
    }
    else {
        
        url = `https://api-football-v1.p.rapidapi.com/v3/fixtures?date=${year}-${month}-${day}&league=${leagueid}&season=${season}`
    }
    
    let data = await fetch(url, options)
                    .then(response => response.json())
                    //.then(response => console.log(response))
                    .catch(err => console.error(err));

    return data;
}