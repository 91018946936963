import { FixtureDetails } from './FixtureDetails.js';
import styles from './css/tileContainer.module.scss';
import { AddFixture } from './AddFixture.js';
import { RemoveTile } from './RemoveTile.js';

export const TileContainer = (props) => { 
    const handleAddFixtureChange = async (compFixture) => {
        props.updateFixtureIds(compFixture.fixtureId, true, props.keyvalue)
    }

    return (
            <div className={`${props.bsWidthClass} ${styles.container} 'mh-100'`}>
                <div className={styles.sub_container} style={{backgroundColor: props.backgroundColor}}>
                    {props.removeTile != null ? <RemoveTile onClick={() => props.removeTile(props.keyvalue)} menuOpen = {props.menuOpen}/> : null}
                    {props.fixtureData === undefined ? <AddFixture handleChange = {handleAddFixtureChange}/> : <FixtureDetails fixtureData = {props.fixtureData} counterColour={props.counterColour}/> }
                </div>
            </div>
    )
}