import { useState } from 'react';
import { signUpUser, logInUSer } from '../services/supabaseService';

export const LogIn = (props) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    
    const [logInEmail, setLogInEmail] = useState();
    const [logInPassword, setLogInPassword] = useState();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);   
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const onSignUp = async (event) => {
        event.preventDefault();
        //send request to supabase
        signUpUser(email, password)
    }

    const handleLogInEmailChange = (event) => {
        setLogInEmail(event.target.value);
    }

    const handleLogInPasswordChange = (event) => {
        setLogInPassword(event.target.value);
    }

    const onLogIn = async (event) => {
        event.preventDefault();
        
        //send request to supabase
        const {data, error} = await logInUSer(logInEmail, logInPassword);

        console.log(data);

        if (error === null) {
            props.setToken(data.session.access_token);
        }
    }

    return (
        <div>
            <div>
                <h1>Sign Up</h1>
                <form onSubmit={onSignUp}>  
                        <input type="text" onChange={handleEmailChange}></input>
                        <br />
                        <input type="password" onChange={handlePasswordChange}></input>
                        <br />  
                        <input type="submit" value="Add"></input>
                </form>
            </div>
            <br/>
            <br/>
            <div>
                <h1>LogIn</h1>
                <form onSubmit={onLogIn}>  
                        <input type="text" onChange={handleLogInEmailChange}></input>
                        <br />
                        <input type="password" onChange={handleLogInPasswordChange}></input>
                        <br />  
                        <input type="submit" value="Log In"></input>
                </form>
            </div>
        </div>
    )
}