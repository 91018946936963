import { Team } from './Team.js';
import { GoalCount } from './GoalCount.js';

export const Score = (props) => {
    return (
        <>
            <Team logo={props.logos[0]} team={props.teams[0]}/>
            <div className="w-1/5 flex justify-center items-center">
                <GoalCount count={props.score[0]} colour={props.counterColour}/>
                <GoalCount count={props.score[1]} colour={props.counterColour}/>
            </div>     
            <Team logo={props.logos[1]} team={props.teams[1]} />
        </>
    )
}