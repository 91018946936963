import styles from './css/removeTile.module.scss';
import { useState } from 'react';

export const RemoveTile = (props) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
      };
    
    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <div className = {styles.surcontainer} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            {(isHovering || props.menuOpen === true) && (
                <div className = {styles.container} onClick={props.onClick}>
                    <img src="delete.png" className={styles.img} alt="remove tile" />
                </div>
            )
            }
        </div>
    )
}