import { useState } from 'react';

export default function useToken() {
    //this stores token in session storage, when tab is closed this is erased.
    //if you ever want to keep the token even if tab is closed, store it in local storage
    //you can do this by changing every instance of 'sessionStorage' to 'localStorage' in this file

    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        return tokenString;
    }

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        sessionStorage.setItem('token', userToken);
        setToken(userToken);
    }

    return {
        setToken: saveToken,
        token
    }
}